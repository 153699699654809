import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl, baseUrlHashless } from "../utilities/Utilities";

export default function PopUpAd() {
    const navigate = useNavigate();
    const [showAd, setShowAd] = useState(false);
    const [adData, setAdData] = useState(null);
    const [isVisible, setIsVisible] = useState(false); // Controls fade-in class

    useEffect(() => {
        // Check if ad was already shown in this session
        if (!sessionStorage.getItem("adShown")) {
            // Fetch ad data from the API
            fetch(`${baseUrl}ad/`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.active) {
                        setAdData(data);
                        sessionStorage.setItem("adShown", "true"); // Set flag in sessionStorage
                        // Set delay for ad display
                        setTimeout(() => {
                            setShowAd(true);
                            setTimeout(() => setIsVisible(true), 50); // Add slight delay for fade-in effect
                        }, 2000); // Show ad after 2 seconds
                    }
                })
                .catch((error) => console.error("Error fetching ad:", error));
        }
    }, []);

    if (!showAd || !adData) return null; // Hide component if ad is not active

    return (
        <div className={`popup ${isVisible ? 'show' : ''}`}>
            <button onClick={() => setShowAd(false)}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
            <img
                onClick={() => navigate(`/${adData.url}`)}
                src={window.innerWidth > 500 ? `${baseUrlHashless}${adData.desktop_img}` : `${baseUrlHashless}${adData.mobile_img}`}
                alt="ad_img"
            />
        </div>
    );
}












// import { faXmark } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import desktop from "../assets/ad_1_desktop.png"
// import { useNavigate } from "react-router-dom";
// import desktop from "../assets/ad_1_mobile.png";

// export default function PopUpAd() {
//     const navigate = useNavigate();

//     return (
//         <>
//         <div className="popup">
//             <button>
//                 <FontAwesomeIcon icon={faXmark} />
//             </button>
//             <img onClick={() => navigate('/about-us/')} src={desktop} alt="ad_img" />
//         </div>
//         </>
//     )
// }